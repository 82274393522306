import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { filter, finalize } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { LoaderService } from '../LoaderService/loader.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isLoading: boolean = false;
  private showHeader = new BehaviorSubject(true); //new Subject<any>();
  showHeader$ = this.showHeader.asObservable();
  private showProjectSideBar = new BehaviorSubject(true); //new Subject<any>();
  showProjectSideBar$ = this.showProjectSideBar.asObservable();
  public showSideBar = true;
  public showAccessControlSideBar = false;

  public selectedProject: any = '';
  public showServerError: boolean = false;
  public modalOpen: boolean = false;
  public modalClose: boolean = false;
  public isPackegeExpired: boolean = false;
  private menuSelection = new Subject<string>();

  public onShowToaster: any = {
    isError: false,
    isSuccess: false,
    isWarring: false,
    title: '',
    message: '',
  };

  sidebarLiElements: any = [
    {
      id: 1,
      title: 'PRODUCT',
      items: [
        {
          id: 11,
          title: 'Bugs',
          active: '',
          icon: 'bug-unfilled',
          active_icon: 'bug-active',
        },
        {
          id: 12,
          title: 'Crashes',
          active: '',
          icon: 'crash-unfilled',
          active_icon: 'crash-active',
        },
        // {
        //   id: 13,
        //   title: 'Feedback Hub',
        //   active: '',
        //   icon: 'feedback-unfilled',
        //   active_icon: 'feedback-active',
        // },
      ],
    },
    {
      id: 2,
      title: 'USER FEEDBACK',
      items: [
        {
          id: 13,
          title: 'Surveys',
          active: '',
          icon: 'feedback-unfilled',
          active_icon: 'feedback-active',
        },
        // {
        //   id: 61,
        //   title: 'Feedback Insights',
        //   active: '',
        //   icon: 'feedback-insight-unfilled',
        //   active_icon: 'feedback-insight-active',
        // },
      ],
    },
    {
      id: 3,
      title: 'SENTIMENT INSIGHTS',
      items: [
        {
          id: 51,
          title: 'Instant Insights',
          active: '',
          icon: 'sentiment-unfilled-icon',
          active_icon: 'sentiment-filled-icon',
        },
        {
          id: 52,
          title: 'Progressive Insights',
          active: '',
          icon: 'api-integration-unfilled',
          active_icon: 'api-integration-filled',
        },
      ],
    },
    {
      id: 4,
      title: 'VERSIONS',
      items: [
        {
          id: 21,
          title: 'Force Update',
          active: '',
          icon: 'force-update-unfilled',
          active_icon: 'force-update-active',
        },
        {
          id: 22,
          title: 'Version History',
          active: '',
          icon: 'version-history-unfilled',
          active_icon: 'version-history-active',
        },
      ],
    },
    // {
    //   id: 5,
    //   title: 'QUALITY',
    //   items: [
    //     {
    //       id: 31,
    //       title: 'Test Suites',
    //       active: '',
    //       icon: 'test-suite-unfilled',
    //       active_icon: 'test-suite-active',
    //     },
    //     {
    //       id: 32,
    //       title: 'Test Runs',
    //       active: '',
    //       icon: 'test-run-unfilled',
    //       active_icon: 'test-run-active',
    //     },
    //   ],
    // },
    // {
    //   id: 6,
    //   title: 'DISTRIBUTION',
    //   items: [
    //     {
    //       id: 41,
    //       title: 'Releases',
    //       active: '',
    //       icon: 'releases-unfilled',
    //       active_icon: 'releases-active',
    //     },
    //     {
    //       id: 42,
    //       title: 'Testers',
    //       active: '',
    //       icon: 'tester-unfilled',
    //       active_icon: 'tester-active',
    //     },
    //   ],
    // },
  ];
  accessControlsidebarLiElements: any = [
    {
      id: 1,
      title: 'WORKSPACE SETTINGS',
      items: [
        {
          id: 11,
          title: 'General',
          active: '',
          icon: 'GeneralActive',
          active_icon: 'bug-active',
        },
        {
          id: 12,
          title: 'Access Control',
          active: '',
          icon: 'Access (1)',
          active_icon: 'Access',
        },
        // {
        //   id: 13,
        //   title: 'Feedback Hub',
        //   active: '',
        //   icon: 'feedback-unfilled',
        //   active_icon: 'feedback-active',
        // },
      ],
    },
  ];
  tab: number = 0;

  accountSideBarElements = [
    {
      id: 1,
      title: 'Profile',
      icon: 'manage-account',
      active_icon: '../../../icons/user-active',
    },
    {
      id: 2,
      title: 'Billings',
      icon: 'billings',
      active_icon: '../../../icons/billing-active',
    },
    {
      id: 3,
      title: 'Plans',
      icon: 'plans',
      active_icon: '../../../icons/icons-plans-active',
    },
  ];

  accountSideBarTab: number = 1;

  private showskeleton = new BehaviorSubject(false); //new Subject<any>();
  showskeleton$ = this.showskeleton.asObservable();

  skeletonValue = new BehaviorSubject(false);

  apiLogs: any = [];
  private showHideUblDashboard: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private removeDatePickerValues: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private showCcmModule: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showPermissionModal = new BehaviorSubject<boolean>(false);
  public showPermissionsModalBox: any = false;
  public visibilityChange = new EventEmitter<boolean>();
  hideSaveAndCancelOnJobApplyPopup: Subject<boolean>;
  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private loaderService: LoaderService // Inject LoaderService
  ) {
    this.hideSaveAndCancelOnJobApplyPopup = new Subject();
  }

  triggerRefresh(value: boolean): void {
    this.showHideUblDashboard.next(value);
  }
  getRefreshObservable(): Observable<boolean> {
    return this.showHideUblDashboard.asObservable();
  }
  setDateEmptyValue(value: boolean): void {
    this.removeDatePickerValues.next(value);
  }
  getDateEmptyValue(): Observable<boolean> {
    return this.removeDatePickerValues.asObservable();
  }
  getPermissionFlag(): Observable<boolean> {
    return this.showPermissionModal.asObservable();
  }
  setPermissionFlag(value: boolean): void {
    this.showPermissionModal.next(value);
  }
  triggerRefreshCcm(value: boolean): void {
    this.showCcmModule.next(value);
  }
  getRefreshObservableCcm(): Observable<boolean> {
    return this.showCcmModule.asObservable();
  }
  // showHeader=true
  setHeaderValue(value: any) {
    this.showHeader.next(value);
  }

  setSidebarValue(value: any) {
    this.showProjectSideBar.next(value);
  }

  //   public isObjectEmpty(object: any) {
  //     if (Object.keys(object).length != 0) {
  //       return false;
  //     }
  //     return true;
  //   }
  public isObjectEmpty(object: any): boolean {
    if (object == null || typeof object !== 'object') {
      return true;
    }
    return Object.keys(object).length === 0;
  }

  public clearSelectedProject() {
    if (this._router.url == '/') {
      localStorage.setItem('selectedProject', '');
      this.showSideBar = false;
    }
  }

  public setSelectedProject(data: any) {
    localStorage.setItem('selectedProject', JSON.stringify(data));
    this.selectedProject = data;

    setTimeout(() => {
      this.setSidebarValue(true);
      this.showSideBar = true;
    }, 800);
  }

  showSuccess(message: string, title: string) {
    //   this.toastr.success(title);
    this.onShowToaster = {
      isError: false,
      isSuccess: true,
      isWarring: false,
      title: message,
      message: title,
    };
    setTimeout(() => {
      this.onShowToaster = {
        isError: false,
        isSuccess: false,
        isWarring: false,
        title: '',
        message: '',
      };
    }, 3000);
  }

  showError(message: string, title: string) {
    //   this.toastr.error(title);
    this.onShowToaster = {
      isError: true,
      isSuccess: false,
      isWarring: false,
      title: message,
      message: title,
    };
    setTimeout(() => {
      this.onShowToaster = {
        isError: false,
        isSuccess: false,
        isWarring: false,
        title: '',
        message: '',
      };
    }, 3000);
  }

  showInfo(message: string, title: string) {
    this.toastr.info(title);
  }

  showWarning(message: string, title: string) {
    this.showError(message, title);
  }

  // * this is for setting selected tab when page runs
  setTab() {
    let url: string = '';
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        url = event.urlAfterRedirects;
        if (url.includes('home')) {
          this.tab = 200;
        } else if (url.includes('bugs')) {
          this.tab = 11;
        } else if (url.includes('crashes')) {
          this.tab = 12;
        } else if (url.includes('/test-suites')) {
          this.tab = 31;
        } else if (url.includes('test-cycle')) {
          this.tab = 32;
        } else if (url.includes('/project/surveys/dashboard/main')) {
          this.tab = 13;
        } else if (url.includes('/quality-screen-detail')) {
          this.tab = 31;
        } else if (url.includes('/project/releases')) {
          this.tab = 22;
        } else if (url.includes('/project/distribution/releases')) {
          this.tab = 41;
        } else if (url.includes('/project/distribution/tester')) {
          this.tab = 42;
        } else if (url.includes('/project/sentiment-analysis')) {
          this.tab = 51;
        } else if (url.includes('/project/api-integration')) {
          this.tab = 52;
        } else if (url.includes('/project/feedback-insights')) {
          this.tab = 61;
        } else if (url.includes('/project/force-update')) {
          this.tab = 21;
        } else if (url.includes('/project/project-settings')) {
          this.tab = 100;
        }
        return;
      });

    if (this._router.url.includes('home')) {
      this.tab = 200;
    } else if (this._router.url.includes('bugs')) {
      this.tab = 11;
    } else if (this._router.url.includes('crashes')) {
      this.tab = 12;
    } else if (this._router.url.includes('test-suites')) {
      this.tab = 31;
    } else if (this._router.url.includes('test-cycle')) {
      this.tab = 32;
    } else if (this._router.url.includes('/project/surveys/dashboard/main')) {
      this.tab = 13;
    } else if (this._router.url.includes('/quality-screen-detail')) {
      this.tab = 31;
    } else if (this._router.url.includes('/project/releases')) {
      this.tab = 22;
    } else if (this._router.url.includes('/project/distribution/releases')) {
      this.tab = 41;
    } else if (this._router.url.includes('/project/distribution/tester')) {
      this.tab = 42;
    } else if (url.includes('/project/sentiment-analysis')) {
      this.tab = 51;
    } else if (url.includes('/project/api-integration')) {
      this.tab = 52;
    } else if (url.includes('/project/feedback-insights')) {
      this.tab = 61;
    } else if (this._router.url.includes('/project/force-update')) {
      this.tab = 21;
    } else if (this._router.url.includes('/project/project-settings')) {
      this.tab = 100;
    }
  }

  setSekeletonValue(value: boolean) {
    this.showskeleton.next(value);
  }

  loader = false;
  setLoader(value: boolean) {
    this.loader = value;
  }

  getLoader() {
    return this.loader;
  }
  appVersion = '';
  setVersionForFilterList(appVersion: string) {
    this.appVersion = appVersion;
  }

  getVersionForFilterList() {
    return this.appVersion;
  }

  convertBytesToGb(bytes: any) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let i = 0;
    for (i; bytes > 1024; i++) {
      bytes /= 1024;
    }
    return bytes.toFixed(1) + ' ' + units[i];
  }

  public generateColor(name: string) {
    if (name !== '-') {
      // Convert name to a numerical value
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }

      // Generate random RGB values based on the numerical value of the name
      let r = (hash & 0xff0000) >> 16;
      let g = (hash & 0x00ff00) >> 8;
      let b = hash & 0x0000ff;

      // Adjust RGB values to a dark tint by reducing their values by 30%
      r = Math.floor(r * 0.7);
      g = Math.floor(g * 0.7);
      b = Math.floor(b * 0.7);

      // Convert RGB to hex format
      let hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

      return '#' + hex;
    } else {
      return '#fff';
    }
  }
  public generateStatusColors(status: string) {
    if (status === 'skipped') {
      return '#92a6c8';
    } else if (status === 'passed') {
      return '#6ad8a7';
    } else if (status === 'failed') {
      return '#e97874';
    } else if (status === 'invalid') {
      return '#8e55f3';
    } else if (status === 'blocked') {
      return '#f6d448';
    } else if (status === 'unexecuted') {
      return '#3A6EA5';
    }
    return '#fff';
  }

  public performPostRequest(apiName: string, body: any, headerVersion?: any) {
    let encryptedData = this.encryptData(body);
    if (headerVersion) {
      var headers = new HttpHeaders({
        Version: headerVersion,
      });
    }
    this.loaderService.show(); // Show loader when request starts

    return this.httpClient.post(apiName, encryptedData, { headers }).pipe(
      finalize(() => {
        this.loaderService.hide(); // Hide loader when request completes
      }),
      map((res) => {
        this.apiLogs.push({ url: apiName, body, response: res });
        let decryptedData = this.decryptData(res);
        return decryptedData;
      })
    );
  }
  public performGetRequest(apiName: string, body: any, headerVersion?: any) {
    let encryptedData = this.encryptData(body);
    if (headerVersion) {
      var headers = new HttpHeaders({
        Version: headerVersion,
      });
    }
    this.loaderService.show(); // Show loader when request starts

    return this.httpClient.get(apiName, { headers }).pipe(
      finalize(() => {
        this.loaderService.hide(); // Hide loader when request completes
      }),
      map((res) => {
        this.apiLogs.push({ url: apiName, body, response: res });
        let decryptedData = this.decryptData(res);
        return decryptedData;
      })
    );
  }

  public encryptData(data: string) {
    return data;
  }
  public decryptData(data: any) {
    return data;
  }
  @Cacheable()
  fetchBugsFiltersList() {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.FECTCH_BUGS_FILTERS_LIST;

    return this.performPostRequest(url, requestBody);
  }
  public setMenuSeclection(session: string) {
    this.menuSelection.next(session);
  }

  public getMenuSeclection(): Observable<string> {
    return this.menuSelection.asObservable();
  }

  getCrashFilters(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };
    let url = environment.workspaceApiUrl + API_ENDPOINTS.CRASHES.CRASH_FILTERS;

    return this.performPostRequest(url, requestBody);
  }
  getOccurrenceFilters(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.CRASHES.OCCURRENCE_FILTERS;

    return this.performPostRequest(url, requestBody);
  }
  getSurveyFilters(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.SURVEY.FETCH_SURVEY_FILTER;

    return this.performPostRequest(url, requestBody);
  }
  getReviewsFilters(surveyKuid: any): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        survey_kuid: surveyKuid,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.SURVEY.FETCH_REVIEWS_FILTER;

    return this.performPostRequest(url, requestBody);
  }
  sendToTopic(requestBody): Observable<any> {
    let url =
      environment.notificationAPiUrl + API_ENDPOINTS.NOTIFICATIONS.SEND_TOPIC;

    return this.performPostRequest(url, requestBody);
  }

  /**
   * Gets permissions from localStorage.
   * @returns The permissions array.
   */
  private getPermissions(): any {
    const permissions = localStorage.getItem('userProjectRole');
    return permissions ? JSON.parse(permissions) : [];
  }

  /**
   * Checks if the given endpoint is allowed.
   * @param endpoint - The API endpoint to check.
   * @returns true if the endpoint is allowed, false otherwise.
   */
  isEndpointAllowed(endpoint: string, epicName: string): boolean {
    const permissions = this.getPermissions();
    if (permissions?.isOwner) {
      return true;
    } else {
      return permissions?.userProjectRole?.some(
        (permission) =>
          permission.resourceEndpoint === endpoint &&
          permission?.epicName === epicName
      );
    }
  }
  isEpicAllowed(epicName: string): boolean {
    const permissions = this.getPermissions();
    if (permissions?.isOwner) {
      return true;
    } else {
      return permissions?.userProjectRole?.some(
        (permission) => permission?.epicName === epicName
      );
    }
  }
}
