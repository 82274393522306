import { Component } from '@angular/core';
import { CommonService } from './core/services/common/common.service';
import { LoaderService } from './core/services/LoaderService/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading = this.loaderService.isLoading$;

  constructor(
    public commonService: CommonService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {}

  title = 'fussion-app-v2';
  showLogs() {}
}
