import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { CommonService } from '../common/common.service';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class BugService {
  private performanceData = new BehaviorSubject<any>(null);
  private uncheckedFilter = new BehaviorSubject<any>(null);
  public selectedFilteredState: any[] = [];

  performanceData$ = this.performanceData.asObservable();
  uncheckedFilter$ = this.uncheckedFilter.asObservable();

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}
  setPerformanceData(data: any) {
    this.performanceData.next(data);
  }
  public getuncheckedFilter(): Observable<string> {
    return this.uncheckedFilter.asObservable();
  }
  setuncheckedFilter(data: any) {
    this.uncheckedFilter.next(data);
  }
  public getPerformanceData(): Observable<string> {
    return this.performanceData.asObservable();
  }
  @Cacheable()
  getAssignee(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.ASSIGNEE.FETCH_ASSIGNEE;

    return this.commonService.performPostRequest(url, body);
  }

  updateResolationStatus(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.RESOLUTION_STATUS.UPDATE_RESOLUTION_STATUS;

    return this.commonService.performPostRequest(url, body);
  }
  updateBugPriority(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.PRIORITY.UPDATE_BUG_PRIORITY;

    return this.commonService.performPostRequest(url, body);
  }
  @Cacheable()
  fetchResolationStatus(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.RESOLUTION_STATUS.FETCH_RESOLUTION_STATUSES;

    return this.commonService.performPostRequest(url, body);
  }

  updateBugAssignee(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.ASSIGNEE.UPDATE_ASSIGNEE;

    return this.commonService.performPostRequest(url, body);
  }

  bugSearch(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.BUG_SEARCH;
    return this.commonService.performPostRequest(url, body);
  }

  deleteBug(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.DELETE_BUG;
    return this.commonService.performPostRequest(url, body);
  }

  submitComment(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.POST_COMMENT;
    return this.commonService.performPostRequest(url, body);
  }

  fetchComment(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.FETCH_COMMENT;
    return this.commonService.performPostRequest(url, body);
  }

  deleteComment(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.DELETE_COMMENT;
    return this.commonService.performPostRequest(url, body);
  }
  updateComment(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.UPDATE_COMMENT;
    return this.commonService.performPostRequest(url, body);
  }

  fetchBugFilters(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.BUGS.FETCH_BUG_FILTERS;
    return this.commonService.performPostRequest(url, body);
  }

  applyBugFilters(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.BUGS.APPLY_BUG_FILTERS;
    return this.commonService.performPostRequest(url, body);
  }

  applyBulkOperations(body: any, type: string = ''): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.BUGS.BULK_OPERATIONS_FOR_BUG;

    if (type === 'export') {
      return this.httpClient
        .post(url, body, {
          responseType: 'blob' as 'json', // Set the responseType to 'blob' to handle binary data (CSV file).
        })
        .pipe(
          map((res) => {
            this.commonService.apiLogs.push({ url: url, body, response: res });
            return res;
          })
        );
    }
    return this.commonService.performPostRequest(url, body);
  }

  inputSearchBugs(body: any): Observable<any> {
    const url = environment.workspaceApiUrl + API_ENDPOINTS.BUGS.SEARCH_BUGS;
    return this.commonService.performPostRequest(url, body);
  }
}
